@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,400italic);
@import url(https://www.osu.edu/assets/fonts/webfonts.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/css/font-awesome.min.css);
/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.cf:after {
  clear: both; }

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
  *zoom: 1; }

.Collapsible {
  background-color: white; }

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #00ac9d;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\F107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .Collapsible__trigger.is-open:after {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; }

body,p,h1,h2,h3,h4 {
	font-family:'proximanova',Arial,sans-serif;
}

html, body{
	height: 100%;
}

#app{
	height: 100%;
}

#map-container {
	height: 100%;
	width: 100%;
}

.leaflet-container {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}
.layerGroupContainer {
	display: block;
	box-sizing: border-box;
	margin: 20px 0px 0px 0px;
}

.legend td {
	vertical-align: middle;
	padding: 0px 5px 0px 5px;
}

.Collapsible__trigger {
	cursor: pointer;
	background: #666666;
}

.Collapsible__trigger:after {
	content: '\F107'
}

 
.queryGroupContainer {
	box-sizing: border-box;
	padding: 0px 0px 10px 0px;
}

.units {
	color: #666666;
	font-style: italic;
}


/* .sidebar p,h1,h2 {
	font-family:'proximanova',Arial,sans-serif;
} */

.sidebar h2, h3 {
	color: #bb0000 !important;
}

.sidebar h2 {
	text-align: center !important;
}

.sidebar h3 {
	text-align: left !important;
}

.sidebar img {
	max-width: 75% !important;
}

.sidebar-content p {
	text-align: left !important;
}

.sidebar-tabs>li.active,.sidebar-tabs>ul>li.active {
	background-color: #bb0000 !important;
}


.sidebar-pane {
	padding: 10px 30px 20px 10px !important;
	box-sizing: border-box;
}

.sidebar-pane h4 {
	margin: 10px 0px 10px 0px !important;
}

.sidebar-close {
	padding: 5px 0px 0px 0px !important;
	box-sizing: border-box;
} 

.sidebar-header {
	background-color: #bb0000 !important;
	margin: -10px -30px -10px -10px !important;
	box-sizing: border-box;
} 
.rc-slider {
	margin: 0px 20px 20px 20px;
	max-width: 75%;
}
