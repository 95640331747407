@import url('https://www.osu.edu/assets/fonts/webfonts.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/css/font-awesome.min.css');

body,p,h1,h2,h3,h4 {
	font-family:'proximanova',Arial,sans-serif;
}

html, body{
	height: 100%;
}

#app{
	height: 100%;
}

#map-container {
	height: 100%;
	width: 100%;
}

.leaflet-container {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}