.queryGroupContainer {
	box-sizing: border-box;
	padding: 0px 0px 10px 0px;
}

.units {
	color: #666666;
	font-style: italic;
}

