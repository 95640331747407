/* .sidebar p,h1,h2 {
	font-family:'proximanova',Arial,sans-serif;
} */

.sidebar h2, h3 {
	color: #bb0000 !important;
}

.sidebar h2 {
	text-align: center !important;
}

.sidebar h3 {
	text-align: left !important;
}

.sidebar img {
	max-width: 75% !important;
}

.sidebar-content p {
	text-align: left !important;
}

.sidebar-tabs>li.active,.sidebar-tabs>ul>li.active {
	background-color: #bb0000 !important;
}


.sidebar-pane {
	padding: 10px 30px 20px 10px !important;
	box-sizing: border-box;
}

.sidebar-pane h4 {
	margin: 10px 0px 10px 0px !important;
}

.sidebar-close {
	padding: 5px 0px 0px 0px !important;
	box-sizing: border-box;
} 

.sidebar-header {
	background-color: #bb0000 !important;
	margin: -10px -30px -10px -10px !important;
	box-sizing: border-box;
} 
.rc-slider {
	margin: 0px 20px 20px 20px;
	max-width: 75%;
}