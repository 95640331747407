.layerGroupContainer {
	display: block;
	box-sizing: border-box;
	margin: 20px 0px 0px 0px;
}

.legend td {
	vertical-align: middle;
	padding: 0px 5px 0px 5px;
}

.Collapsible__trigger {
	cursor: pointer;
	background: #666666;
}

.Collapsible__trigger:after {
	content: '\F107'
}

 